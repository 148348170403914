import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import "../../veiling-buyer.css";
import { Spinner } from "react-bootstrap";
import { CarList } from "../../components/CarList";
import { UserNavigation } from "../../components/UserNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export function FollowingAuctionsPage(props) {
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const token = apiAccessToken;
  const user = userMetadata;
  const [auctions, setAuctions] = useState([]);
  const { t } = useTranslation();
  const [dynamicTitle, setDynamicTitle] = useState("USELL");

  useEffect(() => {
    const titleOptions = [t("following_title"), t("USELL")];
    let index = 0;

    const interval = setInterval(() => {
      index = (index + 1) % titleOptions.length;
      setDynamicTitle(titleOptions[index]);
    }, 2000);

    return () => clearInterval(interval);
  }, [t]);

  useEffect(() => {
    const fetchData = async () => {
      if (!user?.id || !token) return;
      const URL = `https://usell.be/api/following-auctions/${user.id}/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };

      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
        const data = await response.json();
        setAuctions(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [user.id, token]);

  if (!auctions) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status" className="spinner">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      <UserNavigation />
      <motion.div
        className="following-hero"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="following-background"></div>
        <Container className="content content-padding-top-lg content-padding-bottom-lg">
          <motion.h1
            className="following-title"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            {dynamicTitle}
          </motion.h1>
          <motion.h4
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            {t("following_text")}
          </motion.h4>
        </Container>
      </motion.div>
      <Container className="content volgende veilingenpagina">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: 10 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2,
              },
            },
          }}
        >
          <Row>
            <CarList auctions={auctions} following={true} />
          </Row>
        </motion.div>
      </Container>
    </>
  );
}
