import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import { CarList } from "../components/CarList";
import "../veiling-buyer.css";
import { Spinner } from "react-bootstrap";
import { useEffect, useState, useContext } from "react";
import { UserNavigation } from "../components/UserNavigation";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export function AuctionsPage(props) {
  const [auctions, setAuctions] = useState(null);
  const { apiAccessToken } = useContext(AuthContext);
  const token = apiAccessToken;
  const { t } = useTranslation();
  const [dynamicTitle, setDynamicTitle] = useState("USELL");

  useEffect(() => {
    const titleOptions = [t("lopende_title"), t("lopende_title2")];
    let index = 0;

    const interval = setInterval(() => {
      index = (index + 1) % titleOptions.length;
      setDynamicTitle(titleOptions[index]);
    }, 2000);

    return () => clearInterval(interval);
  }, [t]);

  useEffect(() => {
    if (!token) return;
    const URL =
      "https://usell.be/api/current-auctions/?query={id, name, status, end_date, car{id, build_year, kilometers, fuel_type}}";

    const options = {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };

    fetchData();

    async function fetchData() {
      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        console.log(data);
        const ongoingAuctions = data.filter(
          (auction) => auction.status === "ONGOING"
        );
        setAuctions(ongoingAuctions);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [token]);

  if (!auctions) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status" className="spinner">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      <UserNavigation />

      <motion.div
        className="auctions-hero"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="auctions-background"></div>
        <Container className="hero-content content content-padding-top-lg content-padding-bottom-lg">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
            className="lopende-title"
          >
            {dynamicTitle}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
            className="lopende-text"
          >
            {t("lopende_text1")}
            <br />
            {t("lopende_text2")}
          </motion.p>
        </Container>
      </motion.div>

      <Container className="content veilingenpagina">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: 10 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2,
              },
            },
          }}
          className="auction-list-container"
        >
          <Row>
            <CarList auctions={auctions} />
          </Row>
        </motion.div>
      </Container>
    </>
  );
}
