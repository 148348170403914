import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserNavigation } from "../../components/UserNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useSpring, animated } from "@react-spring/web";

export function UserDashboardPage() {
  const { userMetadata } = useContext(AuthContext);
  const { t } = useTranslation();
  const [dynamicTitle, setDynamicTitle] = useState("USELL");
  
    useEffect(() => {
      const titleOptions = [t("dashboard_title"), t("USELL")];
      let index = 0;
  
      const interval = setInterval(() => {
        index = (index + 1) % titleOptions.length;
        setDynamicTitle(titleOptions[index]);
      }, 2000);
  
      return () => clearInterval(interval);
    }, [t]);

  return (
    <>
      <UserNavigation />
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="create-auction-hero"
      >
        <div className="create-auction-background"></div>
        <Container className="content content-padding-top-lg content-padding-bottom-lg dashboard-top">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
            className="text-center text-white responsive-title"
          >
            {dynamicTitle}
          </motion.h1>
        </Container>
      </motion.div>
      <Container fluid className="mt-4">
        <DashboardContent user={userMetadata} />
      </Container>
    </>
  );
}

function DashboardContent({ user }) {
  const { t } = useTranslation();

  const pulseEffect = useSpring({
    from: { transform: "scale(1)" },
    to: { transform: "scale(1.02)" },
    config: { tension: 170, friction: 3 },
    loop: { reverse: true },
  });

  const cardVariants = {
    whileHover: { scale: 1.05 },
    whileTap: { scale: 0.95, rotate: 2 },
    dragEnd: { x: 0, y: 0 },
  };

  if (user.type === "seller" || user.type === "company") {
    return (
      <Row className="admin-hover-card mb-5 pb-5 dashboard-space">
        <Row className="px-0 mx-0 dashboard-space">
          <Col className="col-12 col-md-6 col-lg-6 dashboard-col">
            <Link as={Link} to="/dashboard/mijn-veilingen?filter=active">
              <animated.div style={pulseEffect}>
                <motion.div
                  drag
                  dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                  whileHover="whileHover"
                  whileTap="whileTap"
                  variants={cardVariants}
                >
                  <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                    <Card.Body>
                      <Card.Title>
                        <Row>
                          <Col>
                            <h5>{t("dashboard1")}</h5>
                          </Col>
                          <Col>
                            <i className="bi bi-bar-chart-fill float-end p-3 bg-primary-subtle rounded"></i>
                          </Col>
                        </Row>
                      </Card.Title>
                      <Card.Text className="d-none d-lg-block d-md-block">
                        {t("dashboard2")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </motion.div>
              </animated.div>
            </Link>
          </Col>

          <Col className="col-12 col-md-6 col-lg-6 dashboard-col">
            <Link as={Link} to="/dashboard/maak-veiling">
              <animated.div style={pulseEffect}>
                <motion.div
                  drag
                  dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                  whileHover="whileHover"
                  whileTap="whileTap"
                  variants={cardVariants}
                >
                  <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                    <Card.Body>
                      <Card.Title>
                        <Row>
                          <Col>
                            <h5>{t("dashboard3")}</h5>
                          </Col>
                          <Col>
                            <i className="bi bi-clock-history float-end p-3 bg-success-subtle rounded"></i>
                          </Col>
                        </Row>
                      </Card.Title>
                      <Card.Text className="d-none d-md-block d-lg-block">
                        {t("dashboard4")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </motion.div>
              </animated.div>
            </Link>
          </Col>
        </Row>

        <Row className="px-0 mx-0 gy-1 dashboard-space">
          <Col className="gap-0 col-12 col-md-6 col-lg-6 dashboard-col">
            <Link as={Link} to="/dashboard/afspraken">
              <animated.div style={pulseEffect}>
                <motion.div
                  drag
                  dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                  whileHover="whileHover"
                  whileTap="whileTap"
                  variants={cardVariants}
                >
                  <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                    <Card.Body>
                      <Card.Title>
                        <Row>
                          <Col>
                            <h5>{t("dashboard5")}</h5>
                          </Col>
                          <Col>
                            <i className="bi bi-calendar-check float-end p-3 bg-warning-subtle rounded"></i>
                          </Col>
                        </Row>
                      </Card.Title>
                      <Card.Text className="d-none d-lg-block d-md-block">
                        {t("dashboard6")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </motion.div>
              </animated.div>
            </Link>
          </Col>

          <Col className="gap-0 col-12 col-md-6 col-lg-6 dashboard-col">
            <Link as={Link} to="/dashboard/profiel">
              <animated.div style={pulseEffect}>
                <motion.div
                  drag
                  dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                  whileHover="whileHover"
                  whileTap="whileTap"
                  variants={cardVariants}
                >
                  <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                    <Card.Body>
                      <Card.Title>
                        <Row>
                          <Col className="col-10">
                            <h5 className="text-wrap">{t("dashboard7")}</h5>
                          </Col>
                          <Col>
                            <i className="bi bi-person float-end p-3 bg-info-subtle rounded"></i>
                          </Col>
                        </Row>
                      </Card.Title>
                      <Card.Text className="d-none d-lg-block d-md-block">
                        {t("dashboard8")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </motion.div>
              </animated.div>
            </Link>
          </Col>
        </Row>
      </Row>
    );
  } else if (user.type === "buyer") {
    return (
      <Row className="admin-hover-card mb-5 pb-5 dashboard-space">
        <Row className="px-0 mx-0 dashboard-space">
          <Col className="col-12 col-md-6 col-lg-6 dashboard-col">
            <Link as={Link} to="/veilingen">
              <animated.div style={pulseEffect}>
                <motion.div
                  drag
                  dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                  whileHover="whileHover"
                  whileTap="whileTap"
                  variants={cardVariants}
                >
                  <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                    <Card.Body>
                      <Card.Title>
                        <Row>
                          <Col>
                            <h5>{t("dashboard9")}</h5>
                          </Col>
                          <Col>
                            <i className="bi bi-hourglass-split float-end p-3 bg-primary-subtle rounded"></i>
                          </Col>
                        </Row>
                      </Card.Title>
                      <Card.Text className="d-none d-lg-block d-md-block">
                        {t("dashboard10")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </motion.div>
              </animated.div>
            </Link>
          </Col>

          <Col className="col-12 col-md-6 col-lg-6 dashboard-col">
            <Link as={Link} to="/dashboard/gewonnen">
              <animated.div style={pulseEffect}>
                <motion.div
                  drag
                  dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                  whileHover="whileHover"
                  whileTap="whileTap"
                  variants={cardVariants}
                >
                  <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                    <Card.Body>
                      <Card.Title>
                        <Row>
                          <Col>
                            <h5>{t("dashboard11")}</h5>
                          </Col>
                          <Col>
                            <i className="bi bi-trophy float-end p-3 bg-success-subtle rounded"></i>
                          </Col>
                        </Row>
                      </Card.Title>
                      <Card.Text className="d-none d-md-block d-lg-block">
                        {t("dashboard12")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </motion.div>
              </animated.div>
            </Link>
          </Col>
        </Row>
        <Row className="px-0 mx-0 gy-1 dashboard-space">
          <Col className="col-12 col-md-6 col-lg-6 dashboard-col">
            <Link as={Link} to="/dashboard/afspraken">
              <animated.div style={pulseEffect}>
                <motion.div
                  drag
                  dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                  whileHover="whileHover"
                  whileTap="whileTap"
                  variants={cardVariants}
                >
                  <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                    <Card.Body>
                      <Card.Title>
                        <Row>
                          <Col>
                            <h5>{t("dashboard13")}</h5>
                          </Col>
                          <Col>
                            <i className="bi bi-calendar-check float-end p-3 bg-warning-subtle rounded"></i>
                          </Col>
                        </Row>
                      </Card.Title>
                      <Card.Text className="d-none d-lg-block d-md-block">
                        {t("dashboard14")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </motion.div>
              </animated.div>
            </Link>
          </Col>

          <Col className="col-12 col-md-6 col-lg-6 dashboard-col">
            <Link as={Link} to="/dashboard/profiel">
              <animated.div style={pulseEffect}>
                <motion.div
                  drag
                  dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
                  whileHover="whileHover"
                  whileTap="whileTap"
                  variants={cardVariants}
                >
                  <Card className="fixed-size-card border-0 shadow-sm hover-card dashboard-card">
                    <Card.Body>
                      <Card.Title>
                        <Row>
                          <Col className="col-10">
                            <h5 className="text-wrap">{t("dashboard15")}</h5>
                          </Col>
                          <Col>
                            <i className="bi bi-person float-end p-3 bg-info-subtle rounded"></i>
                          </Col>
                        </Row>
                      </Card.Title>
                      <Card.Text className="d-none d-lg-block d-md-block">
                        {t("dashboard16")}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </motion.div>
              </animated.div>
            </Link>
          </Col>
        </Row>
      </Row>
    );
  }
  return null;
}
