import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import "../veiling-buyer.css";
import { StockList } from "../components/StockList";
import { UserNavigation } from "../components/UserNavigation";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Spinner } from "react-bootstrap";

export function MyStockPage(props) {
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const user = userMetadata;
  const token = apiAccessToken;
  const [stockItems, setStockItems] = useState([]);
  const { t } = useTranslation();
  const [dynamicTitle, setDynamicTitle] = useState("USELL");

  useEffect(() => {
    const titleOptions = ["MIJN STOCK", t("lopende_title2")];
    let index = 0;

    const interval = setInterval(() => {
      index = (index + 1) % titleOptions.length;
      setDynamicTitle(titleOptions[index]);
    }, 2000);

    return () => clearInterval(interval);
  }, [t]);

  useEffect(() => {
    if (!user?.id || !token) return;

    const URL = `https://usell.be/api/user/stock/${user.id}/`;
    const option = {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };

    fetchData();

    async function fetchData() {
      try {
        const response = await fetch(URL, option);
        if (!response.ok)
          throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        console.log(data);
        const tekoopStock = data.filter(
          (stockItem) => stockItem.status === "TEKOOP"
        );
        setStockItems(tekoopStock);
      } catch (error) {
        console.error(error);
      }
    }
  }, [user.id, token]);

  if (!stockItems) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status" className="spinner">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      <UserNavigation />
      <motion.div
        className="winning-hero"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className={"winning-background"}></div>
        <Container
          className={"content content-padding-top-lg content-padding-bottom-lg"}
        >
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
            className="winning-titel"
          >
            {dynamicTitle}
          </motion.h1>
          <motion.h4
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            {t("stock_text3")}
          </motion.h4>
        </Container>
      </motion.div>

      <Container className={"content winnende veilingenpagina"}>
        <Row>
          <StockList stockItems={stockItems} following={true} />
        </Row>
      </Container>
    </>
  );
}
