import React, { useEffect, useRef } from "react";

const regionImages = {
  //Eerste letter hoofdletter
  Antwerpen: "/images/REGIO/ANTWERPEN.png",
  Henegouwen: "/images/REGIO/HENEGOUWEN.png",
  Limburg: "/images/REGIO/LIMBURG.png",
  Luik: "/images/REGIO/LUIK.png",
  Luxemburg: "/images/REGIO/LUXEMBURG.png",
  Namen: "/images/REGIO/NAMEN.png",
  "Oost-Vlaanderen": "/images/REGIO/OOST-VLAANDEREN.png",
  "Vlaams-Brabant": "/images/REGIO/VLAAMS-BRABANT.png",
  "Waals-Brabant": "/images/REGIO/WAALS-BRABANT.png",
  "West-Vlaanderen": "/images/REGIO/WEST-VLAANDEREN.png",
  //kleine letters
  antwerpen: "/images/REGIO/ANTWERPEN.png",
  henegouwen: "/images/REGIO/HENEGOUWEN.png",
  limburg: "/images/REGIO/LIMBURG.png",
  luik: "/images/REGIO/LUIK.png",
  luxemburg: "/images/REGIO/LUXEMBURG.png",
  namen: "/images/REGIO/NAMEN.png",
  "oost-vlaanderen": "/images/REGIO/OOST-VLAANDEREN.png",
  "vlaams-brabant": "/images/REGIO/VLAAMS-BRABANT.png",
  "waals-brabant": "/images/REGIO/WAALS-BRABANT.png",
  "west-vlaanderen": "/images/REGIO/WEST-VLAANDEREN.png",
  //Combinatie edgecase
  "Oost-vlaanderen": "/images/REGIO/OOST-VLAANDEREN.png",
  "Vlaams-brabant": "/images/REGIO/VLAAMS-BRABANT.png",
  "Waals-brabant": "/images/REGIO/WAALS-BRABANT.png",
  "West-vlaanderen": "/images/REGIO/WEST-VLAANDEREN.png",
};

const BelgiumMap = ({ selectedRegion }) => {
  const canvasRef = useRef(null);
  const scaleFactor = 0.5;
  const imageSrc =
    regionImages[selectedRegion] || "/images/Belgie-landkaart.png";

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
      const scaledWidth = img.width * scaleFactor;
      const scaledHeight = img.height * scaleFactor;
      canvas.width = scaledWidth;
      canvas.height = scaledHeight;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, scaledWidth, scaledHeight);
    };

    img.onerror = () => {
      console.error("Fout bij het laden van de afbeelding:", imageSrc);
    };
  }, [selectedRegion, imageSrc]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <canvas ref={canvasRef} style={{ maxWidth: "100%", height: "auto" }} />
    </div>
  );
};

export default BelgiumMap;
