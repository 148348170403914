import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import { StockList } from "../components/StockList";
import { useEffect, useState, useContext } from "react";
import { UserNavigation } from "../components/UserNavigation";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export function ArchivedStockPage() {
  const [stockItems, setStockItems] = useState(null);
  const { apiAccessToken, userMetadata } = useContext(AuthContext);
  const token = apiAccessToken;
  const user = userMetadata;
  const { t } = useTranslation();
  const [dynamicTitle, setDynamicTitle] = useState("USELL");

  useEffect(() => {
    const titleOptions = ["STOCK-ARCHIEF", t("lopende_title2")];
    let index = 0;

    const interval = setInterval(() => {
      index = (index + 1) % titleOptions.length;
      setDynamicTitle(titleOptions[index]);
    }, 2000);

    return () => clearInterval(interval);
  }, [t]);

  useEffect(() => {
    if (!token || !user) return;
    const URL = `https://usell.be/api/archived-stock/${user.id}/`;

    const options = {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };

    async function fetchData() {
      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        console.log(data);
        setStockItems(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [token, user]);

  return (
    <>
      <UserNavigation />
      <motion.div
        className={"auctions-hero"}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className={"auctions-background"}></div>
        <Container
          className={"content content-padding-top-lg content-padding-bottom-lg"}
        >
          <motion.h1
            className="lopende-title"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            {dynamicTitle}
          </motion.h1>
          <motion.h4
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            {t("stock_text3")}
            
          </motion.h4>
        </Container>
      </motion.div>

      <Container className={"content veilingenpagina"}>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: 10 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2,
              },
            },
          }}
        >
          <Row>
            <StockList stockItems={stockItems} />
          </Row>
        </motion.div>
      </Container>
    </>
  );
}
