import { addDays, format, setHours, setMinutes, setSeconds } from "date-fns";
import {
  Col,
  Form,
  Row,
  Button,
  Image,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import Container from "react-bootstrap/Container";
import { UserNavigation } from "../components/UserNavigation";
import { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { FaCar, FaMoneyBillWave } from "react-icons/fa";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebaseConfig";
import axios from "axios";
import { useTranslation } from "react-i18next";

export function SellStockPage(props) {
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const user = userMetadata;
  const { t } = useTranslation();
  const token = apiAccessToken;
  const navigate = useNavigate();
  const [stockItem, setStockItem] = useState({});
  const [car, setCar] = useState({
    transmission: "Automaat",
    drive: "Voorwielaandrijving",
    fuel_type: "Diesel",
  });
  const [address, setAddress] = useState({
    street: "",
    house_number: "",
    zip_code: "",
    city: "",
    province: "",
    country: "",
    phone_number: "",
  });
  const [options, setOptions] = useState([]);
  const [damages, setDamages] = useState([]);
  const [stockItemToSend, setStockItemToSend] = useState(null);
  const [financing, setFinancing] = useState(false);
  const [btwNumber, setBtwNumber] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [generations, setGenerations] = useState([]);
  const [series, setSeries] = useState([]);
  const [trims, setTrims] = useState([]);
  const [boorddocument, setBoorddocument] = useState(null);
  const [kenteken, setKenteken] = useState(null);
  const [kenteken2, setKenteken2] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [dropdownErrors, setDropdownErrors] = useState({
    brands: "",
    models: "",
    generations: "",
    series: "",
    trims: "",
  });

  const handleDropdownError = (dropdown, message) => {
    setDropdownErrors((prevErrors) => ({
      ...prevErrors,
      [dropdown]: message,
    }));
  };

  const handleModalOpen = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleModalClose = () => setShowModal(false);

  const API_BASE_URL = "https://usell.be/api";

  useEffect(() => {
    if (!token) return;
    const fetchBrands = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/car-brands/`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        });

        const cleanedData = response.data.map((item) => ({
          id: item["'id_car_make'"].replace(/'/g, ""),
          name: item["'name'"].replace(/'/g, ""),
        }));

        if (cleanedData.length === 0) {
          handleDropdownError("brands", "Geen merken gevonden.");
        } else {
          handleDropdownError("brands", "");
        }

        setBrands(cleanedData);
      } catch (error) {
        console.error("Error fetching car brands:", error);
        handleDropdownError(
          "brands",
          "Er is een probleem opgetreden bij het ophalen van de merken."
        );
      }
    };
    fetchBrands();
  }, [user, token]);

  const fetchModels = async (makeId) => {
    if (!makeId) {
      resetDropdownsBelow("models");
      return;
    }
    resetDropdownsBelow("models");
    const token = apiAccessToken;
    try {
      const response = await axios.get(
        `${API_BASE_URL}/car-models/${makeId}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      const cleanedData = response.data
        .map((item) => ({
          id: item["'id_car_model'"]
            ? item["'id_car_model'"].replace(/'/g, "")
            : null,
          name: item["'name'"] ? item["'name'"].replace(/'/g, "") : null,
        }))
        .filter((item) => item.id && item.name);

      if (cleanedData.length === 0) {
        handleDropdownError("models", "Geen modellen gevonden voor dit merk.");
      } else {
        handleDropdownError("models", "");
      }

      setModels(cleanedData);
    } catch (error) {
      console.error("Error fetching car models:", error);
      handleDropdownError(
        "models",
        "Er is een probleem opgetreden bij het ophalen van de modellen."
      );
    }
  };

  const fetchGenerations = async (modelId) => {
    if (!modelId) {
      resetDropdownsBelow("generations");
      return;
    }
    resetDropdownsBelow("generations");
    const token = apiAccessToken;
    try {
      const response = await axios.get(
        `${API_BASE_URL}/car-generations/${modelId}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      const cleanedData = response.data
        .map((item) => ({
          id: item["'id_car_generation'"]
            ? item["'id_car_generation'"].replace(/'/g, "")
            : null,
          name: item["'name'"] ? item["'name'"].replace(/'/g, "") : null,
        }))
        .filter((item) => item.id && item.name);

      if (cleanedData.length === 0) {
        handleDropdownError(
          "generations",
          "Geen generaties gevonden voor dit model."
        );
      } else {
        handleDropdownError("generations", "");
      }

      setGenerations(cleanedData);
    } catch (error) {
      console.error("Error fetching car generations:", error);
      handleDropdownError(
        "generations",
        "Er is een probleem opgetreden bij het ophalen van de generaties."
      );
    }
  };

  const fetchSeries = async (generationId) => {
    if (!generationId) {
      resetDropdownsBelow("series");
      return;
    }
    resetDropdownsBelow("series");
    const token = apiAccessToken;
    try {
      const response = await axios.get(
        `${API_BASE_URL}/car-series/${generationId}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      const cleanedData = response.data
        .map((item) => ({
          id: item["'id_car_serie'"]
            ? item["'id_car_serie'"].replace(/'/g, "")
            : null,
          name: item["'name'"] ? item["'name'"].replace(/'/g, "") : null,
        }))
        .filter((item) => item.id && item.name);

      if (cleanedData.length === 0) {
        handleDropdownError(
          "series",
          "Geen series gevonden voor deze generatie."
        );
      } else {
        handleDropdownError("series", "");
      }

      setSeries(cleanedData);
    } catch (error) {
      console.error("Error fetching car series:", error);
      handleDropdownError(
        "series",
        "Er is een probleem opgetreden bij het ophalen van de series."
      );
    }
  };

  const fetchTrims = async (seriesId) => {
    if (!seriesId) {
      resetDropdownsBelow("trims");
      return;
    }
    resetDropdownsBelow("trims");
    const token = apiAccessToken;
    try {
      const response = await axios.get(
        `${API_BASE_URL}/car-trims/${seriesId}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      const cleanedData = response.data
        .map((item) => ({
          id: item["'id_car_trim'"]
            ? item["'id_car_trim'"].replace(/'/g, "")
            : null,
          name: item["'name'"] ? item["'name'"].replace(/'/g, "") : null,
        }))
        .filter((item) => item.id && item.name);

      if (cleanedData.length === 0) {
        handleDropdownError("trims", "Geen trims gevonden voor deze serie.");
      } else {
        handleDropdownError("trims", "");
      }

      setTrims(cleanedData);
    } catch (error) {
      console.error("Error fetching car trims:", error);
      handleDropdownError(
        "trims",
        "Er is een probleem opgetreden bij het ophalen van de trims."
      );
    }
  };

  const resetDropdownsBelow = (level) => {
    switch (level) {
      case "models":
        setModels([]);
        setGenerations([]);
        setSeries([]);
        setTrims([]);
        break;
      case "generations":
        setGenerations([]);
        setSeries([]);
        setTrims([]);
        break;
      case "series":
        setSeries([]);
        setTrims([]);
        break;
      case "trims":
        setTrims([]);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      if (!user?.id || !token) return;
      const profileUrl = `https://usell.be/api/user/${user.Authsub}/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };

      try {
        const response = await fetch(profileUrl, options);
        if (!response.ok)
          throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
        const data = await response.json();
        if (data.address) {
          setAddress(data.address);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchProfile();
  }, [user, user.Authsub, token]);

  const fetchData = useCallback(async () => {
    if (!token) return;
    try {
      const URL =
        "https://usell.be/api/user/stock/create/" + user.id + "/";
      const token = apiAccessToken;
      const options = {
        method: "PATCH",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stockItems: [stockItemToSend],
        }),
      };

      const response = await fetch(URL, options);
      if (!response.ok)
        throw new Error("HTTP ERROR! STATUS: " + response.status);
      navigate("/dashboard/stock/mijn-stock");
    } catch (error) {
      console.error(error);
    }
  }, [token, user, stockItemToSend, apiAccessToken, navigate]);

  useEffect(() => {
    if (!user?.id || !token || !stockItemToSend) return;
    fetchData();
  }, [fetchData, user, token, stockItemToSend]);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    if (selectedFiles.length + newFiles.length > 10) {
      setErrorMessages("Je mag maximaal 10 bestanden uploaden.");
      return;
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setErrorMessages("");
  };

  const removeFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const uploadImages = async (files) => {
    const promises = files.map(async (file) => {
      const storageRef = ref(storage, `stockItems/${file.name}`);
      await uploadBytes(storageRef, file);
      return await getDownloadURL(storageRef);
    });

    return Promise.all(promises);
  };

  const uploadBoorddocument = async (file) => {
    if (!file) return null;
    const storageRef = ref(storage, `stockItems/boorddocuments/${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const uploadKenteken = async (file) => {
    if (!file) return null;
    const storageRef = ref(storage, `stockItem/kenteken/${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const uploadKenteken2 = async (file) => {
    if (!file) return null;
    const storageRef = ref(storage, `stockItem/kenteken2/${file.name}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  };

  const validateFields = () => {
    let errors = {};

    if (!stockItem.name) {
      errors.name = "Naam is verplicht.";
    }
    if (!car.brand_name) {
      errors.brand_name = "Merk is verplicht.";
    }
    if (!car.model) {
      errors.model = "Model is verplicht.";
    }
    if (
      !car.build_year ||
      car.build_year < 1886 ||
      car.build_year > new Date().getFullYear()
    ) {
      errors.build_year =
        "Bouwjaar is verplicht en moet tussen 1886 en het huidige jaar liggen.";
    }
    if (!car.chassis_number || car.chassis_number.length !== 17) {
      errors.chassis_number =
        "Chassisnummer is verplicht en moet precies 17 tekens lang zijn.";
    }
    if (!car.generation) {
      errors.generation = "Generatie is verplicht.";
    }
    if (!car.series) {
      errors.series = "Serie is verplicht.";
    }
    if (!car.finish) {
      errors.finish = "Uitvoering is verplicht.";
    }
    if (!car.color) {
      errors.color = "Kleur is verplicht.";
    }
    if (!car.number_of_doors || car.number_of_doors < 1) {
      errors.number_of_doors =
        "Aantal deuren is verplicht en moet een positief getal zijn.";
    }
    if (!car.kilometers || car.kilometers < 0) {
      errors.kilometers =
        "Kilometerstand is verplicht en moet een positief getal zijn.";
    }
    if (!car.engine) {
      errors.engine = "Motor is verplicht.";
    }

    if (!kenteken) {
      errors.kenteken = "kentekenbewijs is verplicht.";
    }
    if (!kenteken2) {
      errors.kenteken2 = "kentekenbewijs is verplicht.";
    }

    setErrorMessages(errors);
    return Object.keys(errors).length === 0;
  };

  const createStockItem = async (e) => {
    e.preventDefault();
    if (!validateFields()) {
      return;
    }

    const boorddocumentUrl = await uploadBoorddocument(boorddocument);

    const kentekenUrl = await uploadKenteken(kenteken);

    const kenteken2Url = await uploadKenteken2(kenteken2);

    if (selectedFiles.length > 10) {
      setErrorMessages("Je mag maximaal 10 bestanden uploaden.");
      return;
    }

    const start_date = new Date();
    let end_date = addDays(start_date, 5);
    end_date = setHours(end_date, 19);
    end_date = setMinutes(end_date, 0);
    end_date = setSeconds(end_date, 0);

    if (!start_date || !end_date) {
      console.error("Invalid date values");
      return;
    }

    const updatedCar = {
      ...car,
      equipment: "weet ik niet",
      options: options.map((option) => ({ description: option.trim() })),
      damages: damages.map((damage) => ({ description: damage.trim() })),
      car_btw: btwNumber,
      boorddocument: boorddocumentUrl,
      kenteken: kentekenUrl,
      kenteken2: kenteken2Url,
    };

    const updatedAddress = {
      ...address,
      latitude: 0,
      longitude: 0,
      additional_notes: "N/A",
    };

    const start_date_utc = format(start_date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");
    const end_date_utc = format(end_date, "yyyy-MM-dd'T'HH:mm:ss.SSSXXX");

    const newStockItem = {
      ...stockItem,
      car: updatedCar,
      address: updatedAddress,
      financing: financing,
      btw: 21,
      car_btw: btwNumber,
      start_date: start_date_utc,
      end_date: end_date_utc,
      status: "TEKOOP",
      images: [],
      stock_bids: [],
      appointment: {},
    };

    const imageUrls = await uploadImages(selectedFiles);
    newStockItem.images = imageUrls.map((url) => ({ url }));

    setStockItemToSend(newStockItem);
  };

  return (
    <>
      <UserNavigation />
      <div className="create-auction-hero">
        <div className="create-auction-background"></div>
        <div className="hero-overlay"></div>
        <Container className="content content-padding-top-lg content-padding-bottom-lg auctionText">
          <h1 className="text-center text-white tekst-hero">{t("create44")}</h1>
        </Container>
      </div>
      <Container className="content form-container">
        <Form
          onSubmit={createStockItem}
          className="shadow-sm p-4 bg-light rounded"
        >
          <Row className="mb-4">
            <Col>
              <h3 className="mb-3">{t("create45")}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>
                <FaCar className="me-2" />
                {t("create3")}
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) =>
                  setStockItem({ ...stockItem, name: e.target.value })
                }
                placeholder={t("create3_placeholder")}
                className="mb-3"
              />
              {errorMessages.name && (
                <span className="text-danger">{errorMessages.name}</span>
              )}
            </Col>
            <Col>
              <Form.Label>
                <FaMoneyBillWave className="me-2" />
                {t("create4")}
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-startprijs">
                      Vul hier de minimum startprijs in voor de veiling.
                    </Tooltip>
                  }
                >
                  <FaInfoCircle
                    className="ms-2 text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleModalOpen(
                        "De Professionals zullen bieden naar waarde, toon  zo goed mogelijk waarom jouw wagen jouw prijs verdient! Neem zo goed mogelijk je foto's en zorg dat alles goed in beeld is, waaronder interieur, exterieur, opties en schade. Beschrijf de wagen op een waarheidsgetrouwe manier en voor je het weet, is jouw wagen verkocht!"
                      )
                    }
                  />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="number"
                onChange={(e) =>
                  setStockItem({ ...stockItem, bottom_price: e.target.value })
                }
                placeholder={t("create4_placeholder")}
                className="mb-3"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("create5")}</Form.Label>
              <Form.Select
                onChange={(e) => {
                  const selectedBrand = brands.find(
                    (brand) => brand.id === e.target.value
                  );
                  setCar({
                    ...car,
                    brand_name: selectedBrand.name,
                  });
                  fetchModels(selectedBrand.id);
                }}
                className="mb-3"
              >
                <option value="">{t("create5_placeholder")}</option>
                {brands.map((brand) => (
                  <option key={brand.id} value={brand.id}>
                    {brand.name}
                  </option>
                ))}
              </Form.Select>
              {dropdownErrors.brands && (
                <span className="text-danger">{dropdownErrors.brands}</span>
              )}
            </Col>
            <Col>
              <Form.Label>{t("create6")}</Form.Label>
              <Form.Select
                onChange={(e) => {
                  const selectedModel = models.find(
                    (model) => model.id === e.target.value
                  );
                  setCar({
                    ...car,
                    model: selectedModel.name,
                  });
                  fetchGenerations(selectedModel.id);
                }}
                className="mb-3"
              >
                <option value="">{t("create6_placeholder")}</option>
                {models.map((model) => (
                  <option key={model.id} value={model.id}>
                    {model.name}
                  </option>
                ))}
              </Form.Select>
              {dropdownErrors.model && (
                <span className="text-danger">{dropdownErrors.model}</span>
              )}
            </Col>
            <Col>
              <Form.Label>{t("create7")}</Form.Label>
              <Form.Control
                type="number"
                onChange={(e) => setCar({ ...car, build_year: e.target.value })}
                placeholder={t("create7_placeholder")}
                className="mb-3"
              />
              {errorMessages.build_year && (
                <span className="text-danger">{errorMessages.build_year}</span>
              )}
            </Col>
            <Col>
              <Form.Label>{t("create8")}</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) =>
                  setCar({ ...car, chassis_number: e.target.value })
                }
                placeholder={t("create8_placeholder")}
                className="mb-3"
              />
              {errorMessages.chassis_number && (
                <span className="text-danger">
                  {errorMessages.chassis_number}
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("create9")}</Form.Label>
              <Form.Select
                onChange={(e) => {
                  const selectedGeneration = generations.find(
                    (generation) => generation.id === e.target.value
                  );
                  setCar({
                    ...car,
                    generation: selectedGeneration
                      ? selectedGeneration.name
                      : "",
                  });
                  fetchSeries(selectedGeneration.id);
                }}
                className="mb-3"
              >
                <option value="">{t("create9_placeholder")}</option>
                {generations.map((generation) => (
                  <option key={generation.id} value={generation.id}>
                    {generation.name}
                  </option>
                ))}
              </Form.Select>
              {dropdownErrors.generations && (
                <span className="text-danger">{dropdownErrors.generations}</span>
              )}
            </Col>

            <Col>
              <Form.Label>{t("create10")}</Form.Label>
              <Form.Select
                onChange={(e) => {
                  const selectedSeries = series.find(
                    (serie) => serie.id === e.target.value
                  );
                  setCar({
                    ...car,
                    series: selectedSeries ? selectedSeries.name : "",
                  });
                  fetchTrims(selectedSeries.id);
                }}
                className="mb-3"
              >
                <option value="">{t("create10_placeholder")}</option>
                {series.map((serie) => (
                  <option key={serie.id} value={serie.id}>
                    {serie.name}
                  </option>
                ))}
              </Form.Select>
              {dropdownErrors.series && (
                <span className="text-danger">{dropdownErrors.series}</span>
              )}
            </Col>
            <Col>
              <Form.Label>{t("create11")}</Form.Label>
              <Form.Select
                onChange={(e) => {
                  const selectedTrim = trims.find(
                    (trim) => trim.id === e.target.value
                  );
                  setCar({
                    ...car,
                    finish: selectedTrim.name,
                  });
                }}
                className="mb-3"
              >
                <option value="">{t("create11_placeholder")}</option>
                {trims.map((trim) => (
                  <option key={trim.id} value={trim.id}>
                    {trim.name}
                  </option>
                ))}
              </Form.Select>
              {dropdownErrors.trims && (
                <span className="text-danger">{dropdownErrors.trims}</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("create12")}</Form.Label>
              <Form.Control
                type={"text"}
                onChange={(e) => setCar({ ...car, color: e.target.value })}
                placeholder={t("create12_placeholder")}
              />
              {errorMessages.color && (
                <span className="text-danger">{errorMessages.color}</span>
              )}
            </Col>
            <Col>
              <Form.Label>{t("create13")}</Form.Label>
              <Form.Control
                type={"number"}
                onChange={(e) =>
                  setCar({ ...car, number_of_doors: e.target.value })
                }
                placeholder={"Aantal deuren"}
              />
              {errorMessages.number_of_doors && (
                <span className="text-danger">
                  {errorMessages.number_of_doors}
                </span>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("create14")}</Form.Label>
              <Form.Control
                type={"text"}
                onChange={(e) => setCar({ ...car, kilometers: e.target.value })}
                placeholder={"Kilometerstand"}
              />
              {errorMessages.kilometers && (
                <span className="text-danger">{errorMessages.kilometers}</span>
              )}
            </Col>
            <Col>
              <Form.Label>{t("create15")}</Form.Label>
              <Form.Control
                type={"text"}
                onChange={(e) => setCar({ ...car, engine: e.target.value })}
                placeholder={"Motor"}
              />
              {errorMessages.engine && (
                <span className="text-danger">{errorMessages.engine}</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("create16")}</Form.Label>
              <Form.Select
                defaultValue={"Automaat"}
                onChange={(e) =>
                  setCar({ ...car, transmission: e.target.value })
                }
              >
                <option value="Automaat">{t("create16_placeholder1")}</option>
                <option value="Halfautomaat">
                  {t("create16_placeholder2")}
                </option>
                <option value="Manueel">{t("create16_placeholder3")}</option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>{t("create17")}</Form.Label>
              <Form.Select
                defaultValue={"Voorwielaandrijving"}
                onChange={(e) => setCar({ ...car, drive: e.target.value })}
              >
                <option value="Voorwielaandrijving">
                  {t("create17_placeholder1")}
                </option>
                <option value="Achterwielaandrijving">
                  {t("create17_placeholder2")}
                </option>
                <option value="Vierwielaandrijving">
                  {t("create17_placeholder3")}
                </option>
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>{t("create18")}</Form.Label>
              <Form.Select
                defaultValue={"Diesel"}
                onChange={(e) => setCar({ ...car, fuel_type: e.target.value })}
              >
                <option value="Diesel">{t("create18_placeholder1")}</option>
                <option value="Benzine">{t("create18_placeholder2")}</option>
                <option value="Hybride">{t("create18_placeholder3")}</option>
                <option value="LPG">{t("create18_placeholder4")}</option>
                <option value="Elektrisch">{t("create18_placeholder5")}</option>
              </Form.Select>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("create19")}</Form.Label>
              <Form.Control
                as={"textarea"}
                rows={3}
                placeholder={t("create19_placeholder")}
                onChange={(e) => setOptions(e.target.value.split(","))}
              />
            </Col>
            <Col>
              <Form.Label>{t("create20")}</Form.Label>
              <Form.Control
                as={"textarea"}
                rows={3}
                placeholder={t("create20_placeholder")}
                onChange={(e) => setDamages(e.target.value.split(","))}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>
                {t("create21")}
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-boorddocument">
                      Upload een geldig gelijkvormigheidsattest voor de auto.
                    </Tooltip>
                  }
                >
                  <FaInfoCircle
                    className="ms-2 text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleModalOpen(
                        "Het gelijkvormigheidsattest is essentieel om de legaliteit en registratie van de auto aan te tonen."
                      )
                    }
                  />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setBoorddocument(e.target.files[0])}
                className="mb-3"
                accept="application/pdf, image/*"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>
                {t("create35")}
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-kenteken">
                      Upload een geldig inschrijvingsbewijs van de wagen.
                    </Tooltip>
                  }
                >
                  <FaInfoCircle
                    className="ms-2 text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleModalOpen(
                        "Het inschrijvingsbewijs is essentieel om de legaliteit en registratie van de auto aan te tonen."
                      )
                    }
                  />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setKenteken(e.target.files[0])}
                className="mb-3"
                accept="application/pdf, image/*"
              />
              {errorMessages.kenteken && (
                <span className="text-danger">{errorMessages.kenteken}</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>
                {t("create36")}
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-kenteken2">
                      Upload een geldig keuringsattest van de wagen.
                    </Tooltip>
                  }
                >
                  <FaInfoCircle
                    className="ms-2 text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleModalOpen(
                        "Het keuringsattest is essentieel om de legaliteit en registratie van de auto aan te tonen."
                      )
                    }
                  />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setKenteken2(e.target.files[0])}
                className="mb-3"
                accept="application/pdf, image/*"
              />
              {errorMessages.kenteken2 && (
                <span className="text-danger">{errorMessages.kenteken2}</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("create22")}</Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    as={"textarea"}
                    row={3}
                    onChange={(e) =>
                      setStockItem({
                        ...stockItem,
                        description: e.target.value,
                      })
                    }
                    placeholder={"Beschrijving"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label>{t("create37")}</Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    label={t("create24")}
                    checked={financing}
                    onChange={(e) => setFinancing(e.target.checked)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Form.Label>{t("create25")}</Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    type="radio"
                    label="0%"
                    value={0}
                    name="btwStelsel"
                    onChange={(e) => setBtwNumber(e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="radio"
                    label="50%"
                    value={10.5}
                    name="btwStelsel"
                    onChange={(e) => setBtwNumber(e.target.value)}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="radio"
                    label="100%"
                    value={21}
                    name="btwStelsel"
                    onChange={(e) => setBtwNumber(e.target.value)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Label>
                {t("create23")}
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-afbeeldingen">
                      Upload afbeeldingen van de auto (max 10).
                    </Tooltip>
                  }
                >
                  <FaInfoCircle
                    className="ms-2 text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleModalOpen(
                        "Upload afbeeldingen die de auto goed laten zien. Je kunt maximaal 10 afbeeldingen uploaden."
                      )
                    }
                  />
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="file"
                multiple
                onChange={handleFileChange}
                className="mb-3"
                accept="image/*"
              />
            </Col>
          </Row>
          <Row>
            {selectedFiles.map((file, index) => (
              <Col key={index} md={3} className="mb-3">
                <div className="position-relative">
                  <Image
                    src={URL.createObjectURL(file)}
                    thumbnail
                    alt={`selected-file-${index}`}
                  />
                  <Button
                    variant="danger"
                    size="sm"
                    className="position-absolute top-0 start-100 translate-middle"
                    onClick={() => removeFile(index)}
                  >
                    &times;
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
          <Modal show={showModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>Extra Informatie</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalContent}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                Sluiten
              </Button>
            </Modal.Footer>
          </Modal>

          <Row className="mt-4">
            <Col>
              <h3>{t("create26")}</h3>
            </Col>
            <Row>
              <Col lg={10}>
                <Form.Label>{t("create27")}</Form.Label>
                <Form.Control
                  type={"text"}
                  value={address.street}
                  onChange={(e) =>
                    setAddress({ ...address, street: e.target.value })
                  }
                  placeholder={"Straat"}
                />
              </Col>
              <Col lg={2}>
                <Form.Label>{t("create28")}</Form.Label>
                <Form.Control
                  type={"number"}
                  value={address.house_number}
                  onChange={(e) =>
                    setAddress({ ...address, house_number: e.target.value })
                  }
                  placeholder={"Nr."}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Form.Label>{t("create29")}</Form.Label>
                <Form.Control
                  type={"number"}
                  value={address.zip_code}
                  onChange={(e) =>
                    setAddress({ ...address, zip_code: e.target.value })
                  }
                  placeholder={"Postcode"}
                />
              </Col>
              <Col lg={8}>
                <Form.Label>{t("create30")}</Form.Label>
                <Form.Control
                  type={"text"}
                  value={address.city}
                  onChange={(e) =>
                    setAddress({ ...address, city: e.target.value })
                  }
                  placeholder={"Stad"}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>{t("create31")}</Form.Label>
                <Form.Control
                  type={"text"}
                  value={address.province}
                  onChange={(e) =>
                    setAddress({ ...address, province: e.target.value })
                  }
                  placeholder={"Provincie"}
                />
              </Col>
              <Col>
                <Form.Label>{t("create32")}</Form.Label>
                <Form.Control
                  type={"text"}
                  value={address.country}
                  onChange={(e) =>
                    setAddress({ ...address, country: e.target.value })
                  }
                  placeholder={"Land"}
                />
              </Col>
              <Col>
                <Form.Label>{t("create33")}</Form.Label>
                <Form.Control
                  type={"text"}
                  value={address.phone_number}
                  onChange={(e) =>
                    setAddress({ ...address, phone_number: e.target.value })
                  }
                  placeholder={"Telefoonnummer"}
                />
              </Col>
            </Row>
          </Row>
          <button type={"submit"} className={"join-button join-button-cta"}>
            {t("create43")}
          </button>
        </Form>
      </Container>
    </>
  );
}
